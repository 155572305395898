.btn-maxVolume__next {
  text-align: center;
  width: 400px !important;
  max-width: 100% !important;
}

.maxVolumeModal {
  img {
    width: 93px;
    height: 93px;
  }

  span {
    font-size: 34px;
  }

  a {
    color: #3756e9;
  }
}

@media only screen and (max-width: 600px) {
  .modal-dialog {
    max-width: unset !important;
  }

  .maxVolumeModal {
    img {
      width: 93px;
      height: 93px;
    }

    span {
      font-size: 25px;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
