.navbar-custom{
    background-color: #FFF !important;
}

.navbar-brand{
    margin-right: 0 !important;
}

.navbar-expand{
    justify-content: center !important;
}