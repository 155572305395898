.btnBack {
  display: inline-block;
  position: relative;
  border-color: unset;
  border-radius: 28px 28px 28px 28px;
  font-size: 34px;
  font-weight: bold;
  height: 95px;
  max-height: 95px;
  width: 80px;
  max-width: 80px;
  border: none;
  cursor: pointer;
  vertical-align: middle;
  margin-right: 20px;
  background: url("../../assets/images/arrow-left.png") no-repeat center,
    linear-gradient(to right, #cc2b6b 0%, #fb2b62 100%);
}

.btnBack:disabled {
  border-color: unset;
  // border-radius: 28px 28px 28px 28px;
  background: linear-gradient(to right, #e2e2e2 0%, #e2e2e2 100%);
  color: #c6c6c6;
}

.btnBack:focus,
.btnBack:hover,
.btnBack:active {
  // border-radius: 28px 28px 28px 28px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .btnBack {
    height: 65px;
    max-height: 65px;
    width: 80px;
    max-width: 80px;
  }

  .btnBack:before {
    height: 75%;
    background: url("../../assets/images/arrow-left.png") no-repeat 20px center;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
