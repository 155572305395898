body {
  overflow: auto;
  height: 100vh;
}

#root {
  height: inherit;
}

.cover-container {
  height: 100vh;
}

.container {
  // display: table;
}

.header {
  position: relative;
  z-index: 1000;
}

.content {
  // width: 70% !important;
  // margin: 0 auto !important;
  // position: absolute !important;
  // width: 100%;
  // display: inline-block;
  // text-align: center;
  // margin: 0 auto;
  //   margin-top: 20%;
  //   margin-bottom: auto;
  //   margin-left: 10%;
  //   margin-right: 10%;
}

.main {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // overflow: hidden;
}

.navbar {
  // padding: 0;
  background-color: #0d2b47 !important;
  // height: 92px !important;
  // max-height: 92px !important;
}

.vertical-align-middle {
  margin: auto;
  width: 50%;
}

.custom-navbar {
  padding-left: 10%;
  padding-right: 10%;
}

.container-fluid {
  flex-direction: row;
  flex-wrap: nowrap;
}

.navbar-toggle-blocks-left {
  // display: flex;
  // flex-direction: row;
  // flex-wrap: nowrap;
  // justify-content: flex-start;
  // flex-grow: 1;
  order: 1;
}

.navbar-toggle-blocks-center {
}

.navbar-toggle-blocks-right {
  // display: flex;
  // flex-direction: row;
  // flex-wrap: nowrap;
  flex-grow: 2;
  order: 2;
}

.form-group {
  margin-bottom: 0.5rem !important;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.w-unset {
  width: unset;
}

.mw-unset {
  max-width: unset;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1024px) {
  .custom-navbar {
    padding-left: 1% !important;
    padding-right: 1% !important;
  }

  .header__container {
    flex-wrap: unset;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .custom-navbar {
    padding-left: 1% !important;
    padding-right: 1% !important;
  }

  .header__container {
    flex-wrap: unset;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .custom-navbar {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .header__container {
    flex-wrap: unset;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 400px) {
}
