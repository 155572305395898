.volume__wrapper {
  border-radius: 20px;
  border: 3px solid #000;
  min-width: inherit;
  padding: 2px;
  height: 44px;
  position: relative;

  .volume__levelbar {
    position: absolute;
    background-color: rgb(43, 125, 253);
    height: 34px;
    z-index: 10;
    border-radius: 20px;
    padding-right: 6px;
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .volume__container {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    align-items: center;

    .volume__bar {
      margin-left: 15px;
      width: 3px;
      border: 3px solid rgb(255, 255, 255);
      z-index: 20;
      order: 1;
      height: 50%;
      margin-top: 0.1em;
      margin-bottom: 0.1em;
    }

    .volume__icon {
      position: relative;
      min-width: 35px;
      z-index: 20;
      order: 2;
      margin-right: 1%;
      height: 100%;
    }

    .volume__line {
      background-color: rgb(255, 255, 255);
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .modal-dialog {
    padding-left: 2%;
    padding-right: 2%;
  }

  .actionButton {
    font-size: 26px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
