.inputText {
  border-radius: 28px;
  border: 1px solid #c6c6c6;
  height: 50px;
  background-color: #ffffff;
  width: 100%;
  color: #000;
  padding-left: 12px;
}

.inputText:focus,
.inputText:active,
.inputText:hover {
  border-radius: 28px;
  border: 1px solid #3756e9;
  outline: none;
  height: 50px;
  background-color: #ffffff;
  width: 100%;
  color: #000;
}
