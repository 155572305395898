.hero {
  position: relative;
  background-color: #828282;
  overflow: hidden;
}

.hero-content {
  text-align: center;
  position: fixed;
  width: 100%;
  top: 40%;
}

.hero-content__headline {
}

.cover-image {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
}

.primary-text {
  color: #fff;
  font-size: 65px;
  font-weight: bold;
}

.secondary-text {
  color: #ff2b62;
  font-size: 34px;
  font-weight: bold;

  & > span {
    display: block;
  }
}

.btn-getstarted {
  background-color: #ff2b62;
  border-color: #ff2b62;
  background: #ff2b62;
  border-radius: 28px;
  font-size: 18px;
  font-weight: bold;
  padding: 0.375rem 0.75rem;
}

.btn-getstarted:focus,
.btn-getstarted:hover,
.btn-getstarted:active {
  background-color: #ff2b62;
  border-color: #ff2b62;
  background: #ff2b62;
  padding: 0.375rem 0.75rem;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .hero-content {
    margin-left: 12px;
    margin-right: 12px;
    width: unset;
    top: 20%;
  }

  .btn-getstarted {
    background-color: #ff2b62;
    border-color: #ff2b62;
    background: #ff2b62;
    border-radius: 28px;
    font-size: 18px;
    font-weight: bold;
    padding: 0.375rem 0.75rem;
  }

  .btn-getstarted:focus,
  .btn-getstarted:hover,
  .btn-getstarted:active {
    background-color: #ff2b62;
    border-color: #ff2b62;
    background: #ff2b62;
    padding: 0.375rem 0.75rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
