.btnNext {
  text-align: center;
  display: inline-block;
  position: relative;
  border-color: unset;
  border-radius: 28px 28px 28px 28px;
  font-size: 34px;
  font-weight: bold;
  height: 95px;
  max-height: 95px;
  width: 380px;
  max-width: 380px;
  border: none;
  cursor: pointer;
  vertical-align: middle;
  padding: 0;
  // padding-right: 25px;
  background: linear-gradient(to right, #05b6fb 0%, #3757e9 100%);
}

.btnNext:before {
}

.btnNext:disabled {
  border-color: unset;
  // border-radius: 28px 28px 28px 28px;
  background: linear-gradient(to right, #e2e2e2 0%, #e2e2e2 100%);
  color: #c6c6c6;
}

.btnNext:focus,
.btnNext:hover,
.btnNext:active {
  // border-radius: 0 28px 28px 0;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .btnNext {
    height: 65px;
    max-height: 65px;
    width: 250px;
    max-width: 250px;
  }

  .btnNext:before {
    height: 75%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
