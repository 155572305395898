.logo {
  // width: 75%;
}

.header__container {
  justify-content: flex-end !important;
  flex-wrap: nowrap;
}

.needHelp__container {
  display: block;
  text-align: center;
  height: 50px;
  line-height: 50px;
  margin-right: 1rem;

  .needHelp__text {
    color: #fff;
    font-size: 25px;
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }
}

.contactPhone__container {
  .contactPhone__link {
    display: flex;
    align-items: center;
    color: unset;
    text-decoration: none;
    background-color: transparent;

    .contactPhone__icon {
      .icon {
      }
    }

    .contactPhone__text {
      margin-left: 10px;
      color: #fff;
      font-size: 36px;
      font-weight: bold;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

.contactEmail__container {
  margin-left: 10px;

  .contactEmail__link {
    .contactEmail__icon {
      display: none;
      height: 50px;
      width: 50px;
    }

    .contactEmail__text {
      display: block;
      color: #ff2b62;
      font-size: 36px;
      text-decoration: underline;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .contactPhone__container {
    .contactPhone__link {
      .contactPhone__text {
        font-size: 22px;
      }
    }
  }

  .contactEmail__container {
    .contactEmail__link {
      .contactEmail__text {
        font-size: 22px;
      }
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
  .needHelp__container {
    margin-right: 0.1rem;

    .needHelp__text {
      font-size: 16px;
    }
  }

  .contactPhone__container {
    .contactPhone__link {
      .contactPhone__text {
        font-size: 20px;
      }
    }
  }

  .contactEmail__container {
    .contactEmail__link {
      .contactEmail__text {
        font-size: 20px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .needHelp__container {
    margin-right: 0.1rem;

    .needHelp__text {
      font-size: 16px;
    }
  }

  .contactPhone__container {
    .contactPhone__link {
      .contactPhone__text {
        font-size: 16px;
      }
    }
  }

  .contactEmail__container {
    .contactEmail__link {
      .contactEmail__text {
        font-size: 16px;
      }
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .needHelp__container {
    margin-right: 0.1rem;

    .needHelp__text {
      font-size: 16px;
    }
  }

  .contactPhone__container {
    .contactPhone__link {
      .contactPhone__text {
        font-size: 16px;
      }
    }
  }

  .contactEmail__container {
    .contactEmail__link {
      .contactEmail__text {
        font-size: 16px;
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .custom-navbar {
    padding-left: 0;
    padding-right: 0;
  }

  .logo {
    width: 100%;
  }

  .header__container {
    flex-wrap: nowrap !important;
  }

  .needHelp__container {
    display: none;
  }

  .contactEmail__container {
    .contactEmail__link {
      .contactEmail__icon {
        display: block;
        width: 50px;
        height: 50px;

        .icon {
          width: inherit;
          height: inherit;
          color: #ef2258;
        }
      }

      .contactEmail__text {
        display: none;
      }
    }
  }

  .contactPhone__container {
    .contactPhone__link {
      .contactPhone__icon {
        display: block;
        width: 50px;
        height: 50px;

        .icon {
          width: inherit;
          height: inherit;
          color: #ef2258;

          //   background: url("../../assets/images/call.png") no-repeat;
        }
      }

      .test-phone {
        display: none !important;
      }
      .contactPhone__text {
        display: none !important;
      }
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .logo {
    width: 100%;
  }

  .header__container {
    flex-wrap: nowrap !important;
  }

  .needHelp__container {
    display: none;
  }

  .contactEmail__container {
    .contactEmail__link {
      .contactEmail__icon {
        display: block;
        width: 50px;
        height: 50px;

        .icon {
          width: inherit;
          height: inherit;
          color: #ef2258;
        }
      }

      .contactEmail__text {
        display: none;
      }
    }
  }

  .contactPhone__container {
    .contactPhone__link {
      .contactPhone__icon {
        display: block;
        width: 50px;
        height: 50px;

        .icon {
          width: inherit;
          height: inherit;
          color: #ef2258;

          //   background: url("../../assets/images/call.png") no-repeat;
        }
      }

      .test-phone {
        display: none !important;
      }
      .contactPhone__text {
        display: none !important;
      }
    }
  }
}
