.result_container {
  display: grid;
  grid-template-rows: 10% 90%;
  height: inherit;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.user-details__container {
  margin-left: 0;
}

.hearing-loss__container {
  border: 3px solid #0da7f8;
  margin-top: 1rem;
  padding: 0.5rem;
  width: 35%;
  margin-left: 0;
}

.phonetically-words__container {
  border: 3px solid #ff0000;
  margin-top: 1rem;
  padding: 0.5rem;
  width: 35%;
  margin-left: 0;
}

.logo__container {
  position: relative;
  height: fit-content;
}

.chartjs-render-monitor {
  height: 350px !important;
}

.logo__container {
  position: absolute;
  padding: 0.5rem 1rem;
}
