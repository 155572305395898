.playAudio {
  .blueBox {
    background: url("../../assets/images/blue_box.png") no-repeat;
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    vertical-align: middle;
  }

  .audioBox {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .volume {
    background-color: unset;
    border-radius: unset;
    border: unset;
    cursor: pointer;

    img {
      width: 50px;
      height: 50px;
    }

    &:disabled {
      cursor: unset;
    }
  }

  .decrease {
    text-align: right;
  }

  .increase {
    text-align: left;
  }

  .playImage {
    width: 150px;
    height: 150px;
  }

  .pauseImage {
    width: 150px;
    height: 150px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
