* {
  font-family: $primary-font;
}

button {
  color: #fff;
  font-size: 18px;
  text-align: center;
  width: 240px;
  max-width: 240px;
  height: 56px;
  max-height: 56px;
  background-color: #ff2b62;
  border-radius: 28px;
}

.logo-area {
  .logo {
  }
}

.rc-checkbox-inner {
  width: 20px !important;
  height: 20px !important;
  transition: unset !important;
}

.rc-checkbox-checked .rc-checkbox-inner {
  background-color: #3756e9 !important;
  border-color: #3756e9 !important;
}

.rc-checkbox-checked .rc-checkbox-inner:after {
  width: 8px !important;
  height: 12px !important;
  left: 5px !important;
}

.rc-checkbox:hover .rc-checkbox-inner,
.rc-checkbox-input:focus + .rc-checkbox-inner {
  border-color: #3756e9 !important;
}
