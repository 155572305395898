.box__row-image {
  text-align: center;
}

.box__image {
  width: 93px;
  height: 93px;
}

.box__row-copy {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.box__copy {
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .box__image {
    width: 50px;
    height: 50px;
  }

  .box__copy {
    font-size: 1.5rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
