.actionButton {
  border-radius: 28px;
  font-size: 34px;
  font-weight: bold;
  color: #fff;
  padding: 0;
  width: 100%;
  height: 56px;
  background: linear-gradient(to right, #05b6fb 0%, #3757e9 100%);
}

.actionButton:focus,
.actionButton:hover,
.actionButton:active {
  background: linear-gradient(to right, #05b6fb 0%, #3757e9 100%);
}

.actionButton:disabled {
  border-color: unset;
  border-radius: 28px;
  background: linear-gradient(to right, #e2e2e2 0%, #e2e2e2 100%);
  color: #c6c6c6;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
