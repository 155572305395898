.contactform__title {
  font-size: 34px;
  color: #0d2b47;
  text-align: center;
}

.contactform__subtitle {
  font-size: 24px;
  color: #3756e9;
  text-align: center;
}

.row__title {
  margin-bottom: 1rem !important;
}

@media only screen and (max-width: 400px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .contact__container {
    padding-left: 12px;
    padding-right: 12px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
}
