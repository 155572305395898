/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .main {
    // flex: 1;
    // height: inherit;
  }

  .content {
    // width: 95% !important;
    // margin: 0 auto !important;
    // position: relative !important;
    // padding-left: 12px;
    // padding-right: 12px;
  }

  .navbar-toggler {
    width: fit-content !important;
    color: white !important;
    background-color: white !important;
  }

  .logo-area {
    padding-left: 0;

    a {
      width: inherit;

      .logo {
        width: inherit;
      }
    }
  }

  .navbar {
    padding: 0.5rem 0.5rem;
  }

  .contact-phonenumber__text {
    display: none;
  }

  .primary-text {
    font-size: 3rem;
  }
  .secondary-text {
    font-size: 2rem;
  }
}

/* Small devices (portrait tablets and large phones, 400px and less) */
@media only screen and (max-width: 400px) {
}

/* Medium devices (portrait tablets and large phones, 600px and less) */
@media only screen and (max-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and less) */
@media only screen and (max-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and less) */
@media only screen and (max-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and less) */
@media only screen and (max-width: 1200px) {
}
